<template>
  <section class="user-section">
    <h1 class="text-secondary">
      {{ viewName }}
    </h1>

    <router-view />
  </section>
</template>

<script>
export default {
  name: 'UserAppointments',

  computed: {
    viewName() {
      return this.$route.name === 'UserAppointmentsList'
        ? 'Próximos Agendamentos'
        : 'Detalhes do Agendamento';
    },
  },
};
</script>

<style lang="scss" scoped>
.section {
  @apply flex flex-col items-center justify-center;
  @apply w-full p-6 mt-0 mb-6;
  @apply bg-white;
  @apply shadow-xl;
  border-radius: 2rem;
}
</style>
